import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './geriana.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
//other
import { meta } from '../../../metaData';
import Blockquote from '../components/blockquote';

const challengesList = [
  'Caretakers can find needed specialists by search. The clients can choose from experienced caregivers, nurses, physiotherapists or masseurs, depending on their needs. ',
  'Alternatively, clients could post the request for proposals. After publishing, it appears on the job feed. They can specify any job type: live-in, short-term or one-off.',
  'The suppliers will view the job opportunity and apply if it matches the requirements.',
  "The care seekers will receive the incoming proposals and could check the candidates' profile details (location, hourly rates). They could accept or decline the bids based on their preferences.",
  'The caretakers could contact shortlisted candidates via chat and discuss future cooperation.',
  'The caretakers could hire the caregiver by sending them a job offer. If interested, the caregivers could accept it and initiate a contract.',
  'The caretakers could release one-off or recurring payments based on completed work.',
];

function GenianaCase() {
  const data = useStaticQuery(graphql`
    query {
      KarolinaKwiecinskaPhoto: file(relativePath: { eq: "Karolina-Kwiecinska-Photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "geriana-screen-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "geriana-screen-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "geriana-screen-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "geriana-screen-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "geriana-screen-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "geriana-screen-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "geriana-screen-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "geriana-screen-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      isRed
      score={['HealthTech', 'Poland', 'January, 2018', 'Paused']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Building a peer-to-peer platform for caregiving services"
      subTitle="Geriana is a marketplace called to be an all-European network for providing social services. Learn more about Brocoders' commitment to the development of this project."
      metaData={meta.geriana}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Our client, Karolina Kwiecinska, was aiming to change the situation in the caregiving
          industry. She wanted to provide an alternative on the market for how social services are
          provided to those in need. Unlike the traditional way, which brings more complexity and
          routing tasks to get qualified care, she envisioned it operating as a two-sided
          marketplace. Going this way, clients could find and hire service suppliers directly,
          avoiding extra spendings and routines.
        </p>
        <p className={itemStyles.paragraph}>
          Karolina has an extensive strategic supply chain and procurement background in various
          industries. Her knowledge and enthusiasm helped her create Geriana.com, the peer-to-peer
          platform connecting professional health care and social workers with their clients.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Project Background</h2>
        <p className={itemStyles.paragraph}>
          The concept of the platform originates from the observation that currently caregiver
          hiring options in Europe are limited to a few: either through an agency, local councils,
          online ads or word of mouth advertising, each way with its benefits and drawbacks (either
          very expensive or unsafe, or as in the case of local councils – not available for all
          income groups). Therefore, there is a niche in the market to introduce a solution that
          would have the most benefits for the agency, but at a much lower price, still being safer
          than hiring through classified ads.
        </p>
        <p className={itemStyles.paragraph}>
          Additionally, it is expected that the demand for caregivers in Western Europe will
          increase in the near future due to ageing societies, which can be met by hiring migrant
          workers. Finally, there is a gap in employment in the EU among middle-aged women compared
          to men. The policymakers are establishing new programmes to restore them to the labour
          market and caregiver jobs. Those circumstances provide grounds for the emergence of new
          ways of connecting caretakers with caregivers and increasing the supply of caregiving
          jobs, which women in middle age could meet.
        </p>
        <p className={itemStyles.paragraph}>
          The objective of the new solution is to improve market competition, match specific demand
          and supply needs and boost the migration of workers within the EU. Therefore, the drivers
          for the creation of the portal are beyond being a business opportunity to fill in the
          market niche, social and economic.
        </p>
        <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      </div>
      <Blockquote
        img={data.KarolinaKwiecinskaPhoto.childImageSharp.fluid}
        isRating
        text={
          '"Software development is complex work that requires considering many points at the same time. The hardest thing, I guess, is to have the same vision of the product, when you are located in different countries. But I think that the team did their best at this point.\n \n They set new calls, drew new sketches and produced new specifications to match my requirements. They listened to me and used various tools to get on the same page with me."'
        }
        downloadReviewLink="https://bit.ly/331wa8q"
        author={
          <React.Fragment>
            <b>Karolina Kwiecinska</b>
            <br />
            CEO at Geriana
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Project Overview</h2>
        <p className={itemStyles.paragraph}>
          The marketplace is enriched with advanced search and collaboration features. After viewing
          the profile details, a client can shortlist and invite the appropriate candidate to the
          interview. All functions needed for signing a contract and payments procedures are inbuilt
          to the system. There is no need for the care seekers to check the information about
          caregivers since Geriana handles the verification upfront.
        </p>
      </div>
      <Img
        fluid={data.screen3.childImageSharp.fluid}
        className={clsx(itemStyles.container, styles.screen3)}
        alt=""
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          The Geriana platform is dedicated to older people, and so its user interface is adjusted
          accordingly and looks friendly to them. Besides that, the system allows their relatives
          and representatives to join the platform and use the associated accounts.
        </p>
        <p className={itemStyles.paragraph}>
          The platform can be used by various service providers: caregivers, nurses,
          physiotherapists and masseurs. Not only individual workers but organizations could join
          the platform and bid with their proposals. They could submit their propositions for
          various types of work: from live-in, short-term to one-off jobs.
        </p>
        <p className={itemStyles.paragraph}>
          The service should charge clients a percentage-based commission for their payments.
        </p>
        <h2 className={itemStyles.title}>Client’s Requirements</h2>
        <h3 className={itemStyles.subtitle}>The Project Scope</h3>
        <p className={itemStyles.paragraph}>
          Our client needed us to develop the project from scratch. This job included the following
          parts:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Graphic UI/UX design;</li>
          <li>Front-end implementation using React.js;</li>
          <li>Back-end development with Ruby on Rails.</li>
          <li>The project required integration with the Mailgun.</li>
        </ul>
        <h3 className={itemStyles.subtitle}>Location</h3>
        <p className={itemStyles.paragraph}>
          The first release of the product will be focused on the Polish market. Afterwards, it will
          be scaled up to Europe.
        </p>
        <h3 className={itemStyles.subtitle}>Core Values of Geriana</h3>
        <p className={itemStyles.paragraph}>
          Geriana makes the hiring process of caregivers smoother and transparent. The platform
          enables them to get more job opportunities and receive fair earnings. On the other side,
          care seekers could find qualified specialists without spending much time and at a better
          price than hiring through an agency.
        </p>
        <Img fluid={data.screen4.childImageSharp.fluid} className={itemStyles.container} alt="" />
        <h3 className={itemStyles.subtitle}>Key Features</h3>
        <div className={styles.challenges}>
          {challengesList.map((item, i) => (
            <div className={styles.item}>
              <div className={styles.count}>{i + 1}</div>
              <div className={styles.text}>{item}</div>
            </div>
          ))}
        </div>
        <h3 className={itemStyles.subtitle}>Specific Requirements</h3>
        <p className={itemStyles.paragraph}>
          The development team should suggest a simple UI/UX design, friendly to elderly users (the
          target audience of Geriana are people of the age of 45+).
        </p>
        <p className={itemStyles.paragraph}>
          Since the clients are located in Europe, the platform should use the payment gateway
          eligible in these countries.
        </p>
      </div>
      <Img fluid={data.screen5.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Project Development</h2>
        <h3 className={itemStyles.subtitle}>Step 1. Business Analysis</h3>
        <p className={itemStyles.paragraph}>
          We set out the design work from revisiting the UX prototype provided to us by Karolina. It
          reflected the user flows and looked good, though we needed to put some amendments there.
          When checking it, we discovered some missing steps in the user flow. At some points, we
          determined the doubled features on the screens.
        </p>
        <p className={itemStyles.paragraph}>
          In the course of our communication with Karolina, our business analyst managed to collect
          all necessary data pertaining to the product functionality:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Customers' user experience.</li>
          <li>Competitor analysis.</li>
          <li>The final feature scope.</li>
          <li>Main working priorities.</li>
        </ul>
        <h3 className={itemStyles.subtitle}>Step 2. Writing the Documentation</h3>
        <p className={itemStyles.paragraph}>
          While analyzing the project requirements, we found the search filters to be one of the
          most important but time-consuming features. As part of the project specification, we had
          to research payment methods that would be suitable for the platform. Thanks to this, it
          was a new but useful experience to study Paylane, a Polish-based payment gateway.
        </p>
        <p className={itemStyles.paragraph}>
          We created a few documents containing the feature decomposition and user stories by the
          end of this stage. Based on that, our programmers estimated the time needed to complete
          the development work.
        </p>
        <h3 className={itemStyles.subtitle}>Step 3. Drawing the UI/UX Design</h3>
        <p className={itemStyles.paragraph}>
          Based on the provided information, we delivered a new interactive UI/UX design, which
          avoided the previous issues and reflected the suggestions from our client. It involved the
          design elements and patterns, clear to the users with an average or low web experience.
        </p>
        <p className={itemStyles.paragraph}>
          With that said, the prototype served as a specification that helped convey the primary
          user flows to our team. Using this, our UI/UX designer created the final graphic layouts,
          which were turned into the working code.
        </p>
        <h3 className={itemStyles.subtitle}>Step 4. Project's Estimation</h3>
        <p className={itemStyles.paragraph}>
          To evaluate the project, we divided it into functional tasks - features. For each of them,
          we indicated a list of subtasks and the time needed to implement them. Along with that, we
          estimated the project based on a few scenarios: optimistic, pessimistic and most
          realistic. This approach helped us to assess the future workload and avoid possible
          delays.
        </p>
        <p className={itemStyles.paragraph}>
          Our project manager split the whole work into sprints, keeping in mind the main
          priorities. However, we considered that the priorities could change by the end of the
          first milestone, and we will need to revisit our plan. That is not a big deal for us, as
          far as we follow agile methods and help our clients work efficiently.
        </p>
      </div>
      <Img fluid={data.screen6.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          For the first milestone, we determined the following user features:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>A user signs up on the website through either a role: caregiver or client.</li>
          <li>A user can create and update a profile.</li>
          <li>A user can search for service providers or look for job opportunities.</li>
          <li>A user could use the basic search fields as well as advanced.</li>
          <li>The client has an opportunity to pay over a connected banking card.</li>
        </ul>
        <p className={itemStyles.paragraph}>
          We put the tasks to the Jira framework and shared the board with our team. The project
          manager tracked the progress via the dashboard and reported on it to our client daily.
          They used Skype and Slack for their communication.
        </p>
        <h3 className={itemStyles.subtitle}>Step 5. Code Development</h3>
        <p className={itemStyles.paragraph}>
          At the start, our DevOps engineer built the project's infrastructure with Amazon Web
          Services (AWS) and set up the framework environment. After finishing it, the back-end
          developer joined the team and created PostgreSQL and Ruby on Rails architecture. In a
          week, he provided API documentation for the front-end engineer who also joined the team.
        </p>
      </div>
      <Img
        fluid={data.screen7.childImageSharp.fluid}
        className={clsx(itemStyles.container, styles.screen7)}
        alt=""
      />
      <div className={itemStyles.inner}>
        <Team
          color="#62D1E0"
          title="Our team"
          list={[
            { count: 1, title: 'Project\nmanager / BA' },
            { count: 1, title: 'DevOps\nengineer' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Frontend\nEngineer' },
            { count: 1, title: 'Backend\nEngineer' },
          ]}
        />
      </div>
      <Img fluid={data.screen8.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={clsx(itemStyles.inner, itemStyles.lastItem)}>
        <h2 className={itemStyles.title}>Current progress</h2>
        <p className={itemStyles.paragraph}>
          We finalized the first milestones of the project. The platform can be viewed at{' '}
          <a href="https://geriana.com/" target="_blank" className={styles.link}>
            www.geriana.com
          </a>
        </p>
      </div>
    </Layout>
  );
}

export default GenianaCase;
